@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --customizable-options-additional-information-color: #2c2c2c;
    --customizable-options-required-text-color: #dc6d6d;
}

.ProductCustomizableItem {
    &-Label {
        & &:not([class*="-Heading"] > [class*="-Label"]) {
            width: calc(100% - max(21px, var(--checkbox-width)));
            word-break: break-word;
            min-width: 50px;
        }
    }

    &-PriceLabel {
        font-size: 12px;
        font-weight: 500;
    }

    &-Wrapper {
        margin-block: 24px;

        .Field {
            --input-padding-inline: 16px;
            --input-padding-block: 8px;
            --input-padding: var(--input-padding-block)
                var(--input-padding-inline);

            margin-block-start: 8px;
            margin-block-end: 0;

            [type="checkbox"],
            [type="radio"] {
                height: 16px;
                width: 16px;

                & + .input-control {
                    min-width: 16px;
                    height: 16px;
                    width: 16px;
                    margin-inline: 0;
                }
            }

            &-CheckboxLabel,
            &-RadioLabel {
                flex: 0 0 16px;
                gap: 8px;
            }

            &Select {
                &-Select {
                    height: auto;
                    padding-inline: var(--input-padding-inline) 36px;
                    padding-block: var(--input-padding-block);
                    font-size: 13px;
                    line-height: 19px;
                }

                &-Option {
                    margin-block: 0;
                    padding-inline: var(--input-padding-inline) 0;
                    padding-block: var(--input-padding-block);
                    font-size: 13px;
                    line-height: 19px;

                    strong {
                        font-weight: 500;
                    }
                }

                &-Arrow {
                    inset-inline-end: 8px;
                }
            }

            &_type {
                &_textarea,
                &_text {
                    input,
                    textarea {
                        --input-padding: var(--input-padding-block)
                            var(--input-padding-inline);

                        width: 100%;
                        font-size: 13px;
                        line-height: 19px;
                    }

                    & + .Field-SubLabelContainer {
                        margin-block-start: 6px;

                        .Field-SubLabel {
                            font-weight: 400;
                        }
                    }

                    & + .Field-ErrorMessages {
                        margin-block-end: 0;
                    }

                    & + .Field-ErrorMessages + .Field-SubLabelContainer {
                        margin-block-start: 0;

                        .Field-SubLabel {
                            font-weight: 400;
                        }
                    }
                }

                &_date,
                &_datetime-local,
                &_time {
                    input {
                        max-width: 100%;
                        min-width: 250px;
                        height: 45px;
                    }
                }
            }
        }
    }

    &-Content {
        margin-block-start: 17px;

        .Field {
            margin-block-start: 12px;

            input,
            textarea {
                width: 100%;
            }
        }
    }

    &-Required {
        color: var(--customizable-options-required-text-color);
        margin-inline-start: 4px;
    }

    &-Information {
        color: var(--customizable-options-additional-information-color);
        font-size: 12px;
        margin-block-start: 6px;

        @include mobile {
            margin-block-start: 7px;
        }
    }

    &-ExpandableContentContent {
        &_isContentExpanded {
            @include mobile {
                padding-block-start: 0;
                padding-block-end: 14px;
                padding-inline: 14px;
            }

            .Field {
                &:first-child {
                    margin-block-start: 0;
                }
            }
        }
    }

    &-ExpandableContentHeading {
        @include desktop {
            font-weight: 400;
        }

        .ExpandableContent-HeadingAdditional {
            font-weight: 500;
        }
    }

    &-Heading {
        font-size: 12px;
        width: auto;
        display: inline-flex;

        &Price {
            font-size: 12px;
            font-weight: 500;
        }

        & > div {
            width: auto;
        }
    }

    &_isPlaceholder {
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        max-width: 270px;
    }
}
