@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductRelated {
    &-ProductList {
        display: flex;
        gap: 16px;
        align-items: center;
    }

    &-Details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-self: normal;
        padding: 3px 0;
    }

    &-Price {
        display: flex;
        gap: 1rem;

        & p {
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0.05em;
            margin-bottom: 0;
        }
    }

    &-FinalPrice {
        color: $color-other-8;
    }

    &-DiscountPrice {
        text-decoration-line: line-through;
        color: $color-neutral-50;
    }

    &-Checkbox .Field {
        margin-bottom: 0;
    }
}


