@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductCardWishlist {
    &:hover {
        box-shadow: none;
    }
}

@include mobileAndTablet {
    .ProductCardWishlist {
        .ProductCard {
            &-Content {
                padding: 0;
            }
        }
    }
}
