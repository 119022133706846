@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductCompareButton {
    &-Button {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;

        @include default-transition(transform, 0.2s);

        &:hover {
            transform: scale(1.1);
        }
    }

    svg {
        display: block;

        path {
            fill: $color-neutral-90;
        }
    }

    &_isActive {
        background: none;

        svg {
            path {
                fill: $color-primary-50;
            }
        }
    }
}
