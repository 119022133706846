@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductTags {
    padding: 3rem 0 ;
    border-bottom: 1px solid #e7e7e7;

    &-ProductIcons {
        border-bottom: 0;
        display: flex;
        gap: 48px;
        flex-wrap: wrap;
        padding: 3rem 0 ;
    }

    &-ProductIcon {
        width: 55px;
        height: 68px;
    }

    @include mobileAndTablet {
        padding-bottom: 40px;
        padding-top: 40px;

        &-ProductIcons {
            gap: 34px;
            padding: 0 ;
        }
    }
}


@include desktopAndAbove {
    .ProductTags {
        padding-bottom: 40px;
        padding-top: 40px;
    }
}
