@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.RelatedProductsByAttribute {
    &-RelatedProducts {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 17px;

        &_variant_secondary {
            display: flex;
            flex-wrap: wrap;
            grid-column-gap: 0;

            .RelatedProductsByAttribute {
                &-RelatedProduct {
                    width: 62px;
                    height: 44px;
                    margin: 0 8px 10px 0;
                    border: 1px solid transparent;

                    @include default-transition(opacity, .2s);

                    &:hover {
                        border-color: $color-primary-60;
                    }
                }
            }
        }
    }

    &-Title {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
    }

    &-RelatedProduct {
        width: 74px;
        height: 56px;
        background: $color-neutral-5;
        cursor: pointer;
        margin: 0 0 10px 0;
        border: 1px solid $color-neutral-50;
        overflow: hidden;
        opacity: 1;

        @include default-transition();

        img {
            height: 100%;
            width: auto;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
        }

        &:hover, &_isSelected {
            border-color: $color-primary-95;
        }
    }

    &-Label {
        margin-bottom: 8px;

        &_variant_secondary {
            font-size: 12px;
        }
    }
}

@include mobile {
    .RelatedProductsByAttribute {
        &-RelatedProducts {
            &_variant_primary {
                overflow-x: auto;
                white-space: nowrap;
                display: block;
                margin-right: -16px;

                .RelatedProductsByAttribute-RelatedProduct {
                    display: inline-block;
                    margin-right: 17px;
                }
            }
        }
    }
}
