@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductRelatedAccessories {
    border-bottom: 1px solid $color-neutral-30;
    border-top: 1px solid $color-neutral-30;
    padding: 2rem 0;

    & .Image-HeightFull {
        width: 100px;
        height: 100px;
    }

    &-OfferTitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;    
    }

    &-Container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}
