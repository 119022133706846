@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductCompareAttributeRow {
    padding-block: 16px;

    &:first-of-type {
        align-items: center;
    }

    @include mobileAndTablet {
        display: block;
        margin-block: 0;
    }

    @include tabletAndAbove {
        padding-block: 24px;
    }

    @include desktopAndAbove {
        padding-block: 32px;
    }

    &-Title {
        font-size: 16px;
        font-weight: 500;

        @include mobileAndTablet {
            padding-block-end: 16px;

            &:first-child {
                padding-inline-start: 0;
            }
        }
    }

    &-OutOfStock,

    &-Values {
        padding-left: var(--product-compare-item-gap);

        .ProductPrice-PriceWrapper {
            min-width: var(--product-compare-column-width);
            width: var(--product-compare-column-width);
        }

        .ProductPrice-PriceValue_isSpecialPrice {
            margin-right: 12px;
        }

        .ProductPrice_hasDiscount, .ProductPrice_isPreview {
            padding: 0 !important;

            .ProductPrice-Price {
                min-width: min-content;
                width: min-content
            }
        }
    }


    &-Value {
        font-weight: 400;
        font-size: 16px;
        color: $color-neutral-70;

        @include mobileAndTablet {
            padding-inline: 0 var(--product-compare-item-gap);
        }

        @include desktopAndAbove {
            padding-inline: var(--product-compare-item-gap) 0;
        }

        &::before {
            background: $color-neutral-70;
        }

        * {
            font-weight: 400;
            font-size: 16px;
            color: $color-neutral-70;

            &::before {
                background: $color-neutral-70;
            }

            &:first-child {
                margin-block-start: 0;
            }
        }
    }
}
