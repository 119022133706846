@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.BlogRelatedProducts {
    &-Wrapper {
        text-align: center;
        margin-bottom: 40px;

        .Image-Image {
            position: absolute !important;
            object-fit: contain !important;
            object-position: center !important;
        }

        .ProductLinks-Title {
            text-align: left;
            font-style: normal;
            font-weight: bold;
            font-size: 2.4rem;
            line-height: 2.8rem;
        }

        .ProductLinks-List {
            grid-template-columns: repeat(2, 1fr);

            @include wide-desktop {
                grid-template-columns: repeat(4, 1fr);

                .ProductCard {
                    max-width: 254px;
                }
            }

            @include narrow-desktop {
                grid-template-columns: repeat(4, 1fr);
            }

            @include tablet {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        .ProductCard {
            &-Content {
                p {
                    text-align: left;
                }
            }
        }

        @media screen and (min-width:1435px) {
            .ProductCard {
                .ProductCard-ActionElement {
                    padding: 8px 0px 16px;
                    padding-left: 1px;
                }
            }
        }

        @media screen and (max-width:1400px) {
            .ProductCard {
                .ProductCard-ActionElement {
                    padding-right: 0;
                }
            }
        }
    }
}
