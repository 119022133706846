@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductAttributeValue {
    .Field {
        &-CheckboxLabel {
            width: 100%;
        }
    }

    &-Label {
        display: flex;
        justify-content: space-between;
        padding-right: 17px;
        width: 100%;
        font-size: 13px;
    }

    &-LabelText {
        display: block;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &-Count {
        padding-left: 10px;
    }

    &-ColorSwatch, &-ImageSwatch {
        display: block;

        .input-control {
            opacity: 0;
        }

        &_isSelected {
            .ProductAttributeValue {
                &-LabelText {
                    &::after {
                        content: 'x';
                        padding-left: 8px;
                    }
                }
            }
        }
    }

    &-ColorSwatchColor, &-ImageSwatchImage {
        width: 16px;
        height: 16px;
        background: var(--option-background-color);
        border: 1px solid var(--option-border-color);
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 2px;
        --option-size: 12px;

        overflow-y: hidden;
    }

    &-ImageSwatchImage {
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .Field_type_checkbox {
        margin-bottom: 10px;
    }
}

@include mobileAndTablet {
    .ProductAttributeValue {
        &-Label {
            padding-right: 0;
        }
    }
}
