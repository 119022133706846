@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductAdditionalInfo {
    display: flex;
    flex-wrap: wrap;
    padding-top: 24px;
    padding-bottom: 0;
    margin-top: 24px;
    border-top: 1px solid $color-neutral-35;

    &_alternative {
        display: block;

        .ProductAdditionalInfo {
            &-Item {
                border-bottom: 1px solid $color-neutral-30;
                width: 100%;
                margin-bottom: 0;
                padding: 16px 0;
            }

            &-Text {
                display: flex;

                a {
                    display: flex;
                }

                div {
                    &:first-child {
                        color: $color-neutral-90;
                    }
                }
            }

            &-Angle {
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);

                svg {
                    display: block;
                }
            }
        }
    }

    &-Item {
        width: 50%;
        display: flex;
        margin-bottom: 26px;
        align-items: center;
        user-select: none;
        cursor: auto;
    }

    &-Icon {
        margin-right: 15px;
        display: flex;
    }

    &-Angle {
        display: none;
    }

    &-Text {
        div {
            line-height: 18px;

            &:first-child {
                color: $color-neutral-50;
                padding-right: 5px;
            }

            &:last-child {
                color: $color-neutral-90;
                text-decoration: underline;
            }
        }
    }

    &-Drawer {
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.5px;

        a {
            font-weight: 400;
            color: $color-primary-50;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &-AdditionalMethods {
        position: absolute;
        top: 0;
        opacity: 0;
        transform: translate(-100%, -30%);
        transition: all ease-in .3s;
        z-index: 1000;
        background-color: $color-neutral-5;
        -webkit-box-shadow: 3px 3px 12px 0px $color-neutral-60;
        -moz-box-shadow: 3px 3px 12px 0px $color-neutral-60;
        box-shadow: 3px 3px 12px 0px $color-neutral-60;
        min-width: 320px;
        visibility: hidden;

        &_showAdditionalMethods {
            opacity: 1;
            visibility: visible;
        }

        thead {
            th {
                background-color: $color-neutral-60;
                color: $color-neutral-5;
            }
        }
    }

    &-AdditionalMethodsClose {
        position: absolute;
        top: 12px;
        right: 11px;
        z-index: 1;
    }

    &-ItemClickAble {
        cursor: pointer;
    }
}

@include mobileAndTablet {
    .ProductAdditionalInfo {
        border-bottom: none;
        padding-bottom: 20px;

        &-AdditionalMethods {
            left: 50%;
            transform: translate(-50%, -30%);
        }
    }
}

@include tablet {
    .ProductAdditionalInfo {
        flex-wrap: nowrap;
    }
}

@include desktopAndAbove {
    .ProductAdditionalInfo {
        padding-bottom: 30px;
    }
}
