@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.RmaProductItem {
    display: grid;
    grid-gap: 36px;
    padding-block: 19px;
    border-top: 1px solid $color-neutral-35;

    @include desktopAndAbove {
        grid-template-columns: auto 325px;
        grid-gap: 19%;
    }

    &:last-child {
        border-bottom: 1px solid $color-neutral-35;
    }

    & > .Field-Wrapper {
        align-self: center;
    }

    &-Product {
        display: grid;
        grid-gap: 36px;

        @include tabletAndAbove {
            grid-template-columns: auto 110px 1fr;
        }
    }

    &-Quantity {
        display: flex;
        align-items: center;

        & > span {
            padding-inline-start: 19px;
            color: $color-neutral-50;
        }
    }

    &-Form {
        padding-block: 5px 21px;

        & > .Field-Wrapper {
            &:not(:last-child) {
                margin-block-end: 24px;
            }
        }

        .Field {
            &-Label {
                font-size: 12px;
                line-height: 18px;
                padding-block-end: 8px;
            }

            &Textarea {
                --input-padding: 16px;

                textarea {
                    --input-padding: 16px;

                    min-height: 177px;
                }

                * {
                    font-size: 12px;
                    line-height: 18px;

                    &:not(textarea) {
                        pointer-events: none;
                    }
                }

                ul {
                    margin-block-start: 8px;

                    li::before {
                        top: 6px;
                        border-radius: 100%;
                    }
                }
            }

            &Select {
                &-Select {
                    height: 46px;
                    padding-block: 13px;
                    padding-inline-start: 15px;
                    font-size: 13px;
                    line-height: 19.5px;
                }

                &-Option {
                    padding-block: 13px;
                    padding-inline: 15px;
                    font-size: 13px;
                    line-height: 19.5px;
                    margin-block: 0;
                }
            }
        }
    }

    &-Checkbox {
        --checkbox-height: 24px;
        --checkbox-width: 24px;

        @include tabletAndAbove {
            margin-block-end: 0;
        }

        [type="checkbox"] + .input-control {
            border-radius: 3px;
            flex: 0 0 var(--checkbox-width);
            top: 0;

            @include tabletAndAbove {
                margin-inline-end: 0;
            }
        }
    }

    &-Content {
        padding-inline-start: 4px;

        & > * {
            color: $black;

            &:not(:last-child) {
                margin-block-end: 9px;
            }
        }
    }

    &-Name {
        cursor: pointer;
    }

    &-Price {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
    }

    &-Additional {
        display: flex;

        & > * {
            color: $color-neutral-50;

            &:not(:last-child) {
                margin-inline-end: 14px;
            }
        }
    }
}
