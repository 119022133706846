@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPage {
    @include page-container;

    max-width: $page-max-width-pdp;

    &-Wrapper {
        display: grid;
        grid-column-gap: 30px;
        grid-template-rows: min-content min-content;
        grid-template-columns: 100%;
        padding: 0;
        z-index: 2;

        @include desktopAndAbove {
            grid-template-columns: auto 350px;
            grid-template-areas: '. actions';
            padding-block-end: 72px;

            .ProductActions {
                grid-area: actions;
            }
        }

        >.RelatedProducts {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }

    &-RelatedSlider {
        border-top: 1px solid $color-neutral-30;

        .AutoRelatedSlider {
            margin-block-end: 40px;
        }
    }

    &-ContentWrapper {
        overflow-x: hidden;
    }

    .CmsBlock-Wrapper_type_pdp_brands {
        margin-bottom: 170px;
    }
}

@include desktopAndAbove {
    .ProductPage {
        padding-inline: 44px;


        &-ContentWrapper {
            padding: 0 44px;
        }
    }
}

@include mobileAndTablet {
    #bhr-items {
        iframe {
            bottom: 70px !important;
        }
    }

    .ProductPage {
        .CmsBlock-Wrapper_type_pdp_brands {
            margin-bottom: 40px;
        }
    }
}
