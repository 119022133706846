@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

// util class, is applied on <html> on fullscreen image

.overscrollPrevented {
    overscroll-behavior: contain;
}

.ProductGallery {
    display: flex;

    .Image-Image {
        object-fit: contain;
    }

    &-ImagesSwitcher {
        margin-top: 48px;
        text-align: center;

        button {
            width: 266px;
        }
    }

    &-Additional {
        flex: 0 0 auto;
        display: none;

        &_isImageZoomPopupActive {
            position: absolute;
            inset-block-end: 20px;

            .CarouselScrollItem {
                filter: opacity(0.75);

                &_isActive {
                    border-width: 2px;
                    filter: opacity(1);
                }
            }
        }

        &_isWithEmptySwitcher {
            height: 66px;
        }
    }

    &-SliderWrapper {
        flex: 1;

        &_inColumn {
            &_false {
                .ProductGallery-Slider {
                    &_isZoomInCursor {
                        .Image-Image {
                            height: 100%;
                            width: auto;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }

            &_true {
                .ProductGallery-Slider {
                    &_isZoomInCursor {
                        .Slider-Wrapper {
                            display: flex;
                            flex-direction: column;
                            line-height: 1;
                            transform: none;
                            will-change: auto;
                        }

                        .Image {
                            &:nth-child(n + 5) {
                                display: none;
                            }
                        }

                        img {
                            display: block;
                            position: relative;
                        }

                        &.ProductGallery-Slider_showAllImages {
                            .Image {
                                &:nth-child(n + 5) {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }

        .ProductGallery-Slider {
            &_isImageZoomPopupActive {
                .Image-Image {
                    transform: translate(-50%, -50%) !important;
                    width: auto;
                    max-height: 100%;
                    max-width: 100%;
                    top: 50%;
                    left: 50%;
                }
            }
        }
    }

    &-Slider {
        height: 100%;
        opacity: var(--sliderOpacity);

        &_isImageZoomPopupActive {
            cursor: pointer;
        }

        &_isZoomInCursor {
            cursor: zoom-in;
        }
    }

    .react-transform-component,
    .react-transform-element,
    &-SliderImage {
        height: 100%;
        width: 100%;
    }

    &-Iframe {
        display: flex;
        justify-content: center;
        align-items: center;

        iframe {
            display: block;
            width: 600px;
            height: 338px;
            max-width: 100%;
        }
    }
}

@include mobile {
    .ProductGallery {
        padding-bottom: 26px;

        &-Slider {
            height: 245px;
        }

        &-SliderWrapper {
            display: flex;
            flex-direction: column;
            min-height: 245px;
        }

        .Slider-Crumbs {
            height: auto;
            position: static;
            padding-top: 26px;
        }
    }
}

@include tablet {
    .ProductGallery {
        padding-bottom: 60px;

        &-SliderWrapper {
            height: 544px;
        }
    }
}

@include desktopAndAbove {
    .ProductGallery {
        height: 747px;
        position: sticky;
        top: 132px;

        &-SliderWrapper {
            &_inColumn {
                &_false {
                    height: 747px;
                }
            }
        }

        &-Additional {
            display: flex;
            width: fit-content;
            position: relative;
            z-index: 22;
            height: 747px;
        }
    }
}
