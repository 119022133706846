@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductCompare {
    --product-compare-item-gap: 24px;
    --button-height: 47px;

    @include scrollbar;

    &-Heading {
        margin-block-end: 32px;

        @include desktopAndAbove {
            margin-block-end: 63px;
        }
    }

    .ClearButton {
        justify-content: center;
        align-items: flex-end;
        padding-inline: var(--product-compare-item-gap);
        max-width: calc(
            calc(var(--product-compare-sidebar-width) + var(--product-compare-item-gap))
        );

        & > .Button {
            margin-inline-end: 0;
            width: auto;
        }
    }

    &-FirstColumn {
        @include mobileAndTablet {
            width: 100%;
        }

        &.ClearButton > .Button {
            @include mobileAndTablet {
                margin-inline-end: 0;
                width: 100%;
            }
        }
    }

    &-Row {
        margin-block-end: 16px;

        @include tabletAndAbove {
            margin-block-end: 24px;
        }

        @include desktopAndAbove {
            margin-block-end: 32px;
        }
    }

    &-Price {
        padding: 0 var(--product-compare-item-gap);
        width: calc(var(--product-compare-column-width));
        font-size: 20px;
        font-weight: 500;
    }

    .ProductPrice {
        width: var(--product-compare-column-width);
        margin-block: 0;
        align-items: center;

        @include mobileAndTablet {
            padding-inline-start: 0;
        }

        &-PriceValue {
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
        }

        &-HighPrice {
            font-size: 15px;
        }

        & > span {
            margin-right: 16px;
        }

        &_hasDiscount {
            .ProductPrice {
                &-PriceValue {
                    color: $color-other-8;
                }
            }

            .DiscountLabel {
                display: none;
            }
        }
    }
}
