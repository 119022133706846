@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.RewardsPointsForProduct {
    display: flex;
    gap: 16px;
    margin: 16px 0;

    & p {
        display: flex;
        gap: 5px;
        color: $dark-transparent;
        align-items: center;

        & span {
            font-weight: 500;
            color: $color-neutral-90;
        }
    }
}
