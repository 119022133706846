@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductLinksSlider {
    border-top: 1px solid $color-neutral-30;
    margin-bottom: 40px;
    padding-top: 20px;

    .TypographyHeader {
        text-align: left;
        margin-top: 22px;
    }

    &-Item {
        padding: 0 25px 0 0;
    }

    &-Loader {
        height: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@include mobile {
    .ProductLinksSlider {
        margin: 40px -16px 20px 0;
    }
}

@include tablet {
    .ProductLinksSlider {
        margin: 40px -20px 20px 0;

        &-Loader {
            height: 442px;
        }
    }
}

@include mobileAndTablet {
    .ProductLinksSlider {
        .slick-slider .slick-list {
            overflow-y: hidden;
            overflow-x: scroll;
            scroll-behavior: auto;

            &::-webkit-scrollbar {
                display: none;
                height: 0;
            }
        }
    }
}

@include desktopAndAbove {
    .ProductLinksSlider {
        &-Item {
            padding: 0 10px 0;
        }

        &-Loader {
            height: 445px;
        }

        .slick-slider {
            margin-left: -10px;
            margin-right: -10px;
        }

        .slick-list {
            overflow: visible;
            z-index: 5
        }

        .SliderPrevArrow, .SliderNextArrow {
            height: 100%;
            bottom: 0;
            transform: none;
            display: flex;
            align-items: center;
            width: 35px;
            background: $color-neutral-5;
            z-index: 10
        }

        .SliderPrevArrow {
            left: -34px;
        }

        .SliderNextArrow {
            justify-content: flex-end;
            right: -34px;
        }
    }
}
