@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.ProductActions {
    line-height: 16px;

    &-SKU {
        & p {
            font-size: 12px;
            line-height: 18px;
            color: $dark-transparent-64;
            margin: 14px 0;
        }
    }

    &-Brand {
        font-weight: bold;
        opacity: .48;
        font-size: 12px;
    }

    &-Title {
        margin-bottom: 12px;
        font-weight: 500;
    }

    &-ShortDescription {
        font-size: 14px;
        line-height: 20px;
        margin-block: 16px;
    }

    &-Stock {
        font-weight: bold;
        order: 0;

        @include desktop {
            margin-inline-end: 24px;
        }

        @include mobile {
            margin-block-end: 10px;
        }
    }

    &-Sku {
        order: 1;

        & + .ProductActions-Sku {
            margin-inline-start: 1ex;
        }
    }

    &-Review {
        order: 2;
        font-weight: 700;
        color: var(--primary-base-color);
        cursor: pointer;
        inset-block-end: -2px;
        font-size: 14px;

        &:hover {
            color: var(--primary-dark-color);
        }
    }

    &-Labels {
        display: flex;

        .Label {
            margin-right: 10px;
        }
    }

    &-Section {
        &_type {
            &_sku {
                display: flex;

                @include mobile {
                    flex: 1;
                    align-items: flex-end;
                    flex-direction: column;
                }
            }

            &_alerts {
                margin-block-start: 10px;
                margin-block-end: 10px;
            }
        }
    }

    &-Schema {
        min-width: 150px;
    }

    &-SchemaUrl {
        display: none;
    }

    &-PriceWrapper {
        .ProductPrice {
            align-items: center;

            &-PriceBadge {
                display: none;
                padding-bottom: 0;
            }

            &-PriceValue {
                font-size: 30px;
                line-height: 26px;
            }

            &-Price {
                display: block;
            }

            &-HighPrice {
                order: 0;
                display: inline-block;
                font-size: 15px;
            }

            &_hasDiscount {
                .ProductPrice-PriceValue {
                    color: $color-other-8;
                }
            }
        }
    }

    &-ActionButtons {
        display: flex;
        flex-direction: row-reverse;
    }

    &-ActionsWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include desktop {
            margin-block: 18px;
        }

        .ProductCompareButton {
            margin-inline-start: auto;
        }

        &_isWithoutPriceTotal .ProductActions-Section_type_sku {
            @include mobile {
                float: end;
            }
        }
    }

    &-AddToCartFixed {
        position: fixed;
        bottom: 0;
        z-index: 5;
        background-color: var(--color-white);
        width: 100%;
        display: flex;
        padding: 9px 16px;
        border-block-start: 1px solid var(--primary-divider-color);
        inset-inline-start: 0;
        opacity: 0;
        visibility: hidden;

        @include default-transition(opacity);

        &_visible {
            opacity: 1;
            visibility: visible;
        }

        .ProductWishlistButton {
            margin-inline: 18px 4px;
            margin-block: auto;
        }

        .Field {
            &-Wrapper {
                &_type {
                    &_number {
                        @include mobile {
                            display: flex;
                        }
                    }
                }
            }
        }

        .AddToCart {
            height: 47px;

            @include mobile {
                margin-bottom: 0;
            }
        }
    }

    &-AddToCartWrapper {
        align-items: center;
        display: flex;
        margin-block-start: 24px;
        position: static;

        .ProductActions-AddToCart {
            --button-height: 48px;

            margin-bottom: 0;
        }

        .ProductCompareButton,
        .ProductWishlistButton {
            background: none;
            width: auto;
            height: auto;
            margin-left: 24px;

            svg {
                width: 26px;
                height: 26px;
            }

            path {
                fill: $color-neutral-50;
            }
        }

        &_isPrerendered {
            @include mobile {
                inset-block-end: 0;
            }
        }

        .Field-ErrorMessages {
            margin-left: 0;
            position: absolute;
            top: 45px;
        }
    }

    &-AddToCartWrapper, &-AddToCartFixed {
        .ProductCompareButton,
        .ProductWishlistButton {
            background: none;
            width: auto;
            height: auto;
            margin-left: 24px;

            svg {
                width: 26px;
                height: 26px;
            }

            path {
                fill: $color-neutral-50;
            }
        }

        .ProductWishlistButton {
            order: 1;

            svg {
                width: 24px;
                height: 24px;
            }

            &_isInWishlist {
                path {
                    fill: $color-neutral-90;
                }
            }
        }

        .ProductCompareButton {
            &_isActive {
                path {
                    fill: $color-neutral-90;
                }
            }
        }
    }

    &-Qty {
        &.Field {
            margin-block: 0;
            margin-inline-end: 10px;

            input {
                pointer-events: initial;
                border: 0;
            }
        }

        button {
            cursor: pointer;

            &:hover {
                border-color: var(--primary-dark-color);
            }
        }
    }

    &-AttributesWrapper {
        @include mobile {
            margin-block-end: 16px;
        }

        &.animate {
            animation: shake 820ms .5ms cubic-bezier(.36, .07, .19, .97) both;
        }
    }

    &-GroupedItems {
        margin-block-start: 24px;
    }

    &-Reviews {
        margin-block-end: 24px;
        display: flex;

        .ProductReviewRating {
            margin-inline-end: 24px;
        }
    }

    &-Wrapper {
        position: relative;
    }

    .ProductConfigurableAttributes-Expandable {
        margin-block-start: 24px;
    }

    .ExpandableContent:first-of-type {
        border-block-start: 0;
    }
}

@include mobile {
    .ProductActions {
        &-ActionButtons {
            position: absolute;
            top: -40px;
            right: 0;
        }

        .DiscountLabel {
            display: block;
            width: fit-content;
        }
    }
}

@include tablet {
    .ProductActions {
        &-AddToCartWrapper {
            .ProductActions-AddToCart {
                max-width: 255px;
            }
        }
    }
}

@include mobileAndTablet {
    .ProductActions {
        .DiscountLabel {
            margin-bottom: 0;
        }
    }
}

@include desktopAndAbove {
    .ProductActions {
        &-ActionButtons {
            position: absolute;
            top: 0;
            right: 0;
        }

        &-ActionsWrapper {
            margin-bottom: 12px;
        }

        &-PriceWrapper {
            .ProductPrice {
                display: block;

                &-HighPrice {
                    padding-bottom: 15px;
                }
            }
        }

        &-Omnibus {
            padding-right: 75px;
        }
    }
}
