@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductCompareItem {
    justify-content: space-between;
    padding: 0;

    &-AddToCartBtnWrapper {
        width: 100%;
    }
    
    &-Figure {
        margin-right: 16px;

        img {
            width: 110px;
            height: 82px;
        }
    }

    &-Title {
        font-size: 13px;
    }

    &-Actions {
        .ProductWishlistButton {
            margin-inline-end: 0;
            background-color: transparent;
            height: 20px;
            width: 20px;

            svg {
                height: 16px;
                width: 19px;

                path {
                    fill: $color-neutral-50;
                }
            }
        }
    }

    &-AddToCartBtn {
        flex: 0 1 auto;
        width: 100%;
    }

    &-Rating {
        margin-bottom: 11px;
    }

    button {
        .ProductCompareItem {
            &-CloseBtn {
                top: 0;
                right: -10px;
            }
        }
    }
}
