@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviewItem {
    &-ReviewAuthor {
        margin-block: 0;
        font-size: 14px;
    }

    &-ExtraInfo {
        font-size: 12px;
        padding-top: 20px;
    }
}
