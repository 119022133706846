@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPrice {
    display: flex;

    &-BundleFrom , &-BundleTo {
        &_hasDiscount {
            .ProductPrice-HighPrice {
                margin-right: 10px;
            }
        }
    }

    &-HighPrice {
        order: 1;
        color: $color-neutral-50;
        text-decoration: line-through;
        margin: 0;

        &::after {
            display: none;
        }
    }

    &-Price {
        .DiscountLabel {
            display: inline-block;
            margin-right: 12px;
        }
    }

    .DiscountLabel {
        display: inline-block;
        margin-right: 12px;
    }

    &-PriceBadge {
        padding: 0 5px;
    }

    &-PriceValue {
        &_isSpecialPrice {
            color: $color-other-8;
        }
    }
}

@include mobile {
    .ProductPrice {
        font-size: 12px;

        &-HighPrice {
            width: fit-content;

            &::after {
                top: 6px;
            }
        }
    }
}

@include mobileAndTablet {
    .ProductPrice {
        &_hasDiscount {
            display: flex;
            gap: 5px;
        }
    }
}
