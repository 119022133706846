@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductTabs {
    border-block-end: 2px solid $color-neutral-30;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &-Wrapper {
        max-width: 1180px;
        margin-left: auto;
        margin-right: auto;
        padding: 0;

        & .ExpandableContent-Heading {
            font-size: 20px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0px;
        }
    }
}

#StickyContainer {
    .ProductTabs {
        &-Wrapper {
            padding: 0;
            margin-left: 0;
            margin-right: 380px;
        }
    }
}

@include mobileAndTablet {
    .ProductTabs {
        justify-content: flex-start;
        overflow: hidden;
        overflow-x: auto;
        padding-top: 40px;
        gap: 30px;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }

        &-Button {
            width: max-content
        }

        @include default-transition(opacity);

        &_inViewPort {
            opacity: 1;
            visibility: visible;
        }
    }
}

@include tablet {
    .ProductTabs {
        top: 125px;
    }
}

@include tabletAndAbove {
    .ProductTabs {
        justify-content: flex-start;
    }
}

@include desktopAndAbove {
    .ProductTabs {
        &-Wrapper {
            padding: 0 20px;
        }
    }
}
