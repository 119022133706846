@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, .7);
    --product-card-reviews-background: rgba(243, 243, 243, .55);
    --product-card-wishlist-button-background: rgba(243, 243, 243, .78);
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: var(--product-card-wishlist-button-background);
    --product-card-compare-button-color: var(--product-card-wishlist-button-color);
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 32px;
    --product-card-background: var(--color-white);
    --option-margin-block: 0 12px;
    --option-margin-inline: 0 12px;
}

.ProductCard {
    padding: 0;

    p {
        margin-bottom: 0;
    }

    &:hover {
        @include desktop {
            box-shadow: 0 15px 20px rgba(0, 0, 0, 0.08);
            z-index: 4;

            .ProductCard {
                &-VisibleOnHover {
                    background: var(--product-card-background);
                    opacity: 1;
                    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.08);
                    z-index: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    flex-grow: 1;
                    position: absolute;
                    inset-block-start: 100%;
                    width: 100%;
                }

                &-FigureAlternative, &-ProductActions {
                    opacity: 1;
                }
            }
        }
    }

    &::before {
        content: none;
    }

    &-LabelsWithActions {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &-Labels {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &-DiscountLabel {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
    }

    &-DeliveryTime {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 16px;
        text-align: left;

        span {
            color: $color-teriary-1;
        }
    }

    &-Content {
        background: var(--product-card-background);
        padding-inline: 14px;
        display: flex;
        flex-direction: column;
    }

    &-Name {
        padding-block-end: 2px;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 8px;
        font-weight: normal;
        margin-top: 0;
        text-align: left;
    }

    &-Picture {
        padding-block-end: 100%;
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        inset-block-end: 0;
        margin: auto;
        inset-block-start: 0;
    }

    &-Image {
        display: inline-block;
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-PriceWrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    &-Price {
        width: 100%;
        letter-spacing: 0.5px;
        margin-bottom: 14px;
    }

    .ProductPrice {
        &-PriceValue {
            &_isSpecialPrice {
                color: $color-other-8;
                margin-right: 12px;
            }
        }

        &-HighPrice {
            font-size: 12px;
            letter-spacing: 0;
        }

        .DiscountLabel {
            display: none;
        }
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        font-size: 10px;
        margin-block-end: 0;
    }

    &-Figure {
        flex-grow: 1;
    }

    &-FigureAlternative,
    &-FigureRelatedProduct {
        background: $color-neutral-5;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        opacity: 0;

        @include default-transition(opacity, .2s);
    }

    &-FigureRelatedProduct {
        opacity: 1;
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:hover,
        &:focus {
            text-decoration: none;
            color: initial;
        }
    }

    &-ProductActions {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 8px;
        margin-right: 8px;
        opacity: 0;

        .ProductWishlistButton {
            margin-bottom: 30px;
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    &-AddToCartWrapper {
        display: flex;
        margin-bottom: 32px;

        .AddToCart {
            height: 48px;
            margin-left: 10px;
        }

        .ProductCard-Link {
            .AddToCart {
                width: auto;
            }
        }
    }

    &-VisibleOnHover {
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
        padding-block-start: 0;
        padding-block-end: 0;
        padding-inline: 24px;
        opacity: 0;
        position: absolute;
        inset-block-start: 100%;
        display: none;
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 40px;
        }
    }

    &-FigureReview {
        margin-bottom: 16px;
    }

    &-Reviews {
        margin-bottom: 12px;
    }


    &_layout_list {
        margin-bottom: 30px;
        margin-left: 90px;
        margin-right: 90px;
        padding: 24px 0;

        &:hover {
            .ProductCard-AddToCartWrapper {
                opacity: 1;
            }
        }

        .NotificationList {
            &-Element {
                @include desktop {
                    &_isLeft {
                        left: -720px !important;
                    }
                }
            }
        }

        .ProductCard {
            &-Content {
                justify-content: space-between;
                margin-inline-start: 30px;
                flex-direction: row;
                align-items: start;
                flex: 1;
            }

            &-Link {
                flex-direction: row;
                width: 100%;
            }

            &-ActionWrapper {
                align-items: flex-end;
                display: flex;
                flex-direction: column;
                margin-top: 5px;
            }

            &-ProductActions {
                flex-direction: row-reverse;
                display: flex;
                position: relative;
                top: 0;
                right: 0;
                opacity: 1;

                > div {
                    margin-block-end: 0;
                    margin-left: 30px;
                }

                .ProductWishlistButton {
                    border-radius: 0;
                    overflow: visible;

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }

                .ProductCompareButton {
                    border-radius: 0;
                    overflow: visible;

                    svg {
                        width: 25px;
                        height: 23px;
                    }
                }
            }

            &-MainInfo {
                flex: 1;
                margin-top: 15px;
            }

            &-PriceWrapper {
                flex-grow: unset;
            }

            &-Name {
                font-size: 16px;
                margin-bottom: 15px;
            }

            &-FigureReview {
                margin-bottom: 0;
                margin-left: 104px;
            }

            &-Picture {
                display: block;
                width: 128px;
            }

            &-Labels {
                position: absolute;
                left: 0;
                z-index: 2;
                top: 50%;
                transform: translateY(-50%);
                align-items: start;
            }

            &-DiscountLabel {
                position: relative;
            }

            &-VisibleOnHover {
                padding-left: 286px;
            }

            &-AddToCartWrapper {
                margin-top: 15px;
                margin-bottom: 0;
                opacity: 0;

                @include default-transition();

                .AddToCart {
                    width: 180px;
                }
            }
        }

        .ProductPrice {
            &-HighPrice {
                font-size: 16px;
            }

            &-Price {
                font-size: 20px;
                margin-right: 0;
            }
        }

        .ProductActions-AttributesWrapper, .RelatedProductsByAttribute {
            max-width: 550px;
        }
    }
}

@include mobile {
    .ProductCard {
        &_isSlider {
            .ProductCard {
                &-Content {
                    padding: 6px 0 0;
                }

                &-Name {
                    margin-bottom: 9px;
                }

                &-Price {
                    order: 1;

                    .ProductPrice {
                        font-size: 14px;
                    }
                }

                &-LabelsWithActions {
                    display: none;
                }
            }
        }
    }
}


@include mobile {
    .ProductCard {
        &_layout_grid {
            .NotificationList {
                &-Element {
                    &_isLeft {
                        left: -50px !important;
                    }
                }
            }
        }

        &_variant_secondary {
            &-Content {
                border-bottom: 1px solid $color-teriary-8;
            }
        }
    }
}

@include mobileAndTablet {
    .ProductCard {
        &_isSlider {
            .ProductCard-ProductActions {
                display: none;
            }

            .ProductCard-VisibleOnHover {
                display: none;
            }
        }

        &_layout_grid {
            .ProductCard {
                &-Content {
                    padding: 6px 0 0;
                }

                &-Name {
                    margin-bottom: 9px;
                    overflow: hidden;
                }

                &-Price {
                    order: 1;

                    .ProductPrice {
                        font-size: 14px;
                    }
                }

                &-ProductActions {
                    opacity: 1;

                    .ProductWishlistButton {
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}
